<template>
  <div class="d-flex justify-content-end">
    <dropdown-menu :items="actions" :select="onActionSelect">
      <font-awesome-icon icon="ellipsis-h"
    /></dropdown-menu>
  </div>
</template>

<script>
import EventBus from "../../../helpers/EventBus";
import get from "lodash/get";

const FAMILY_ACTIONS = [{ id: "DELETE", text: "Rimuovi" }];

export default {
  name: "TableActions",

  computed: {
    actions() {
      const isActive =
        this.data.id ===
        get(this.$store, "state.connections.current.family.id");
      return FAMILY_ACTIONS.filter((a) => {
        return a.id === "DELETE" && isActive ? false : true;
      });
    },
  },
  methods: {
    onActionSelect: function (args) {
      const { id } = args.item;

      if (id === "DELETE") {
        if (
          confirm(
            `Confermi di voler rimoovere il conto "${this.data.name}"?`
          ) == true
        ) {
          EventBus.$emit("family:remove", this.data);
        }
      }
    },
  },
};
</script>
