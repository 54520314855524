<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("user.profile.families.title") }}
        </div>
      </CCol>
      <CCol sm="auto">
        <CButton color="primary" @click="onAddEmail()">
          AGGIUNGI CONTO
        </CButton>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <ejs-grid
          :dataSource="dm"
          :allowPaging="true"
          :allowSorting="true"
          :query="query"
          ref="grid"
        >
          <e-columns>
            <e-column headerText="Nome" field="name"></e-column>
            <e-column
              headerText="Tipo"
              field="f_type"
              :template="typeTemplate"
            ></e-column>
            <e-column headerText="Ruolo" :template="roleTemplate"></e-column>
            <e-column
              headerText="Data creazione"
              field="ts_created"
              :template="createdTemplate"
            ></e-column>
            <e-column headerText="" :template="actionsTemplate"></e-column>
          </e-columns>
        </ejs-grid>
      </CCardBody>
    </CCard>

    <CModal :show="modal.show" :closeOnBackdrop="false" :centered="true">
      <template #header>
        <h5 class="modal-title">AGGIUNGI CONTO</h5>
      </template>

      <CAlert v-if="modal.error.hasGeneralErrors()" color="danger" class="mb-3">
        {{ modal.error.general().join(" ") }}
      </CAlert>

      <form action="" @submit.prevent="addBill">
        <CInput
          v-model="modal.form.name"
          label="Nome"
          type="text"
          :isValid="modal.error.isValidField('name')"
          :invalidFeedback="modal.error.fieldError('name')"
          required
        />

        <CSelect
          label="Tipo"
          :options="modal.familyTypeOptions"
          :value.sync="modal.form.type"
          :isValid="modal.error.isValidField('f_type')"
          :invalidFeedback="modal.error.fieldError('f_type')"
          custom
        />
      </form>

      <template #footer>
        <CButton color="primary" variant="outline" @click.prevent="closeModal"
          >ANNULLA</CButton
        >
        <vue-ladda
          :loading="modal.loading"
          data-style="zoom-in"
          button-class="btn btn-primary px-4"
          @click.prevent="addBill"
          >CONFERMA</vue-ladda
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { Page, Sort } from "@syncfusion/ej2-vue-grids";
import { GetDataManagerNew } from "../../../ds";
import { Query } from "@syncfusion/ej2-data";
import TableDate from "./TableDate";
import TableType from "./TableType";
import TableRole from "./TableRole";
import TableActions from "./TableActions";
import EventBus from "../../../helpers/EventBus";
import errorResponse from "../../../helpers/error";
import { getFamilyTypeOptions } from "../../../helpers/options";

export default Vue.extend({
  name: "Family",

  data() {
    const dm = GetDataManagerNew("auth_family");
    const familyTypeOptions = getFamilyTypeOptions();
    const query = new Query().sortBy("name", "ascending");

    return {
      dm: dm,
      query: query,
      error: errorResponse(),
      modal: {
        show: false,
        loading: false,
        error: errorResponse(),
        familyTypeOptions: familyTypeOptions,
        form: {
          name: "",
          type: familyTypeOptions[0].value,
        },
      },

      typeTemplate: () => {
        return {
          template: {
            extends: TableType,
            propsData: {
              field: "f_type",
              options: getFamilyTypeOptions(),
            },
          },
        };
      },

      createdTemplate: () => {
        return {
          template: {
            extends: TableDate,
            propsData: {
              field: "ts_created",
            },
          },
        };
      },

      roleTemplate: () => {
        return { template: TableRole };
      },

      actionsTemplate: () => {
        return { template: TableActions };
      },
    };
  },
  provide: {
    grid: [Page, Sort],
  },

  mounted() {
    EventBus.$on("family:remove", this.onRemove);
  },

  beforeDestroy() {
    EventBus.$off("family:remove", this.onRemove);
  },

  methods: {
    onAddEmail() {
      this.modal.error.reset();
      this.modal.form.name = "";
      this.modal.form.type = this.modal.familyTypeOptions[0].value;
      this.modal.show = true;
    },

    closeModal() {
      this.modal.show = false;
    },

    addBill() {
      const requestParams = {
        name: this.modal.form.name,
        f_type: this.modal.form.type,
      };
      this.modal.error.reset();
      this.dm
        .insert(requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Conto aggiunto con successo",
            color: "success",
            autohide: true,
          });
          this.modal.show = false;
          this.$refs.grid.refresh();
          this.$store.dispatch("connections/fetchAll");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.modal.error.set(body.errors);
        });
    },

    removeEmail(id) {
      this.error.reset();
      this.dm
        .remove("", id)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Conto rimosso con successo",
            color: "success",
            autohide: true,
          });
          this.$refs.grid.refresh();
          this.$store.dispatch("connections/fetchAll");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    onRemove(item) {
      this.removeEmail(item.id);
    },
  },
});
</script>
