<template>
  <div>
    {{ getRoleDisplay() }}
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "TableRole",

  methods: {
    getRoleDisplay() {
      return get(this.data, "role.get_r_type_display", "");
    },
  },
};
</script>
